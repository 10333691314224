import { mock, Random } from 'mockjs';

const gernerate = (i: number) => {
  let month = Random.date('MM');
  return mock({
    c1: `DM00${i}`,
    c2: `精彩365结算模型`,
    c6: '市场费用',
    c7: Random.integer(0, 1),
    c8: Random.integer(0, 1),
    c11: Random.cname(),
    c12: '2021-11-30 ' + Random.time(),
    c13: Random.cname(),
    c14: '2021-11-30 ' + Random.time()
  });
};

let d = [];
for (let i = 100; i < 111; i++) {
  d.push(gernerate(i));
}

export const list = d;

export const columns = [
  { title: '模型编码', dataIndex: 'c1', width: 120 },
  { title: '模型名称', dataIndex: 'c2' },
  { title: '业务类型', dataIndex: 'c6', width: 120 },
  {
    title: '启用模型',
    dataIndex: 'c7',
    width: 120,
    scopedSlots: {
      customRender: 'c7'
    }
  },
  {
    title: '公共模型',
    dataIndex: 'c8',
    width: 120,
    scopedSlots: {
      customRender: 'c8'
    }
  },
  { title: '创建人', dataIndex: 'c11', width: 100 },
  { title: '创建时间', dataIndex: 'c12', width: 200 },
  { title: '最近操作人', dataIndex: 'c13', width: 120 },
  { title: '最近操作时间', dataIndex: 'c14', width: 200 },
  {
    title: '操作',
    dataIndex: 'action',
    fixed: 'right',
    width: 200,
    scopedSlots: {
      customRender: 'action'
    }
  }
];
