
























































import { FilterItem } from '@/interfaces/filter-items';
import MenuMixin from '@/mixins/menu-mixin';
import { mixins } from 'vue-class-component';
import { Vue, Component } from 'vue-property-decorator';
import TablePageMixin from '@/mixins/table-page-mixin';
import { list, columns } from './consts';
@Component({
  components: {}
})
export default class Todo extends mixins(MenuMixin, TablePageMixin) {
  columns = columns;
  list = list;
  activeKey = 1;

  created() {
    this.total = 100;
  }

  open() {}

  confirm(msg: string) {
    this.$confirm({
      title: '提示',
      content: msg,
      okText: '确认',
      cancelText: '取消',
      onOk: async () => {},
      onCancel: async () => {}
    });
  }

  reVisible: boolean = false;
  openMsg(type: number) {
    this.reVisible = true;
  }

  showCard = [
    { title: '待复核结算单', number: 5, type: 0, tip: 'xxxx', unit: '预警' },
    { title: '复核通过结算单', number: 16, type: 1, tip: 'xxxx', unit: '预警' },
    { title: '复核驳回结算单', number: 5, type: 2, tip: 'xxxx', unit: '预警' },
    { title: '已复核结算单', number: 20, type: 3, tip: 'xxxx', unit: '预警' }
  ];

  filterItems: FilterItem[] = [
    { label: '模型编码', key: 'alertCode', type: 'input' },
    { label: '模型名称', key: 'alertCode', type: 'input' },
    { label: '业务类型', key: 'reviewDate', type: 'select', options: [] }
  ];

  filter: any = {};
  page = 1;
  excuteFilter(res: object) {
    this.page = 1;
    this.filter = res;
  }

  resetFilter() {
    this.page = 1;
    this.filter = {};
  }

  handleBatch() {}
}
